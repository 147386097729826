import React, {Component} from 'react';
import axios from 'axios';
import getCoverSize from '@agilie/canvas-image-cover-position';
import Fail from './Fail/';
import Loading from './Loading/';
import Step1 from './Step1/';
import Step2 from './Step2/';
import Step3 from './Step3/';
import './App.css';
// import { nobgImage } from './config.js';

const posterCopy = [
	{
		text: 'Att göra skillnad är mitt uppdrag.',
		image: './assets/images/poster-copy/attgoraskillnadarmittuppdrag.png'
	},
	{
		text: 'Oroliga tider är min grej.',
		image: './assets/images/poster-copy/oroligatiderarmingrej.png'
	},
	{
		text: 'Oroliga tider – ditt jobb är mitt jobb.',
		image: './assets/images/poster-copy/oroligatider-dittjobbarmittjobb.png'
	},
	{
		text: 'Rådgivning är min grej.',
		image: './assets/images/poster-copy/radgivningarmingrej.png'
	},
	{
		text: 'Arbetsrätt är mitt jobb.',
		image: './assets/images/poster-copy/arbetsrattarmittjobb.png'
	},
	{
		text: 'Ditt jobb är mitt jobb.',
		image: './assets/images/poster-copy/dittjobbarmittjobb.png'
	}
];

/*const obfuscate = (str) =>  {
	var bytes = [];
	for (var i = 0; i < str.length; i++) {
		var charCode = str.charCodeAt(i); // pad the string to 3 digits
		charCode = String("000" + charCode).slice(-3);
		bytes.push(charCode);
	}
	return bytes.join('');
}*/
// console.log(obfuscate('tZ23Ye5jxBCm8sgfcRFQPvUC')); // AS dev key
// console.log(obfuscate('p7ZjgcqkbwS2r2m7zTJvhQqV')); // Vision live key

const deobfuscate = (str) => {
	var string = "";
	var chunks = str.match(/.{1,3}/g);
	for (var i = 0; i < chunks.length; i++) {
		string += String.fromCharCode(parseInt(chunks[i], 10));
	}
	return string;
}

class App extends Component {

	constructor(props : Props) {
		super(props);

		this.state = {
			innerHeight: 0,
			currentStep: 0,
			isLoading: false,
			didFail: false,
			userFile: null,
			mergedImage: null,
			errorMessage: 'Ett fel har uppstått, var god försök igen lite senare. (Felkod: 00)'
		};
	}

	componentDidMount() {
		window.addEventListener('resize', () => this.updateInnerHeight());
		window.addEventListener('orientationchange', () => this.updateInnerHeight());
		this.updateInnerHeight();
	}

	updateInnerHeight() {
		const { innerHeight } = window;
		clearTimeout(this.innerHeightTimeout);
		this.innerHeightTimeout = setTimeout(this.setState({innerHeight}), 100);
	}

	onStep1Done(file) {
		this.setState({ userFile: file, currentStep: 1 });
	}

	onStep2Done(body, name) {
		this.setState({ isLoading: true }, () => this.createMergedImage(body, name));
	}

	createMergedImage(body, name) {
		name = name ? name : '';
		const overlayImage = posterCopy[0].image;
		this.loadUserFile()
			.then((userImage) => this.fetchNoBgImage(userImage))
			.then((nobgImage) => this.drawImages(nobgImage, overlayImage))
			.then((canvas) => this.drawText(canvas, body, name))
			.then((mergedImage) => this.setState({mergedImage, currentStep: 2, isLoading: false}))
			.catch(error => {
				console.log('createMergedImage error', error);
				this.setState({didFail: true, errorMessage: error.message, isLoading: false})
			});
	}

	loadUserFile() {
		return new Promise((resolve, reject) => {
			const { userFile } = this.state;

			if (userFile) {
				if (userFile.size > 10000000) {
					return reject(new Error('Bilden du laddat upp är för stor, försök med en annan bild. (Felkod: 07)'));
				}

				const fileReader = new FileReader();
				fileReader.onload = e => {
					const userImage = e.target.result;
					resolve(userImage);
				};
				fileReader.onerror = () => reject(new Error('Bilden du laddat upp går inte att läsa - kontrollera filformat eller försök med en annan bild. (Felkod: 01)'));
				fileReader.readAsDataURL(userFile);
			}
		});
	}

	fetchNoBgImage(userImage) {
		return new Promise((resolve, reject) => {
			axios.request({
				url: 'https://api.remove.bg/v1.0/removebg',
				method: 'post',
				data: {
					image_file_b64: userImage,
					size: 'full', // full/preview
					// scale: '90%', //for scaling up small person
					// position: '50% 100%', //for centering person
					type: 'person',
					format: 'jpg',
					bg_image_url: 'https://vision-affisch.s3.amazonaws.com/background.jpg'
				},
				headers: {
					'Content-Type': 'application/json',
					'X-Api-Key': deobfuscate('112055090106103099113107098119083050114050109055122084074118104081113086'),
					'Accept': 'application/json'
				},
				encoding: null
			})
			.then(response => {
				if (response.status === 200) {
					return resolve('data:image/jpeg;base64,' + response.data.data.result_b64);
				}

				switch (response.status) {
					case 400:
						return reject(new Error('Bilden går inte att behandla - det kan vara att den är för stor, att filen är korrupt, eller att det inte finns en person på bilden. (Felkod: 02)'));
					case 429:
						return reject(new Error('Tyvärr är det så många som försöker skapa affischer nu. Försök igen lite senare. (Felkod: 03)'));
					default:
						return reject(new Error('Ett fel har uppstått, var god försök igen lite senare. (Felkod: 04)'));
				}
			})
			.catch(error => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response);

					switch (error.response.status) {
						case 400:
							return reject(new Error('Bilden går inte att behandla - det kan vara att den är för stor, att filen är korrupt, eller att det inte finns en person på bilden. (Felkod: 02)'));
						case 429:
							return reject(new Error('Tyvärr är det så många som försöker skapa affischer nu. Försök igen lite senare. (Felkod: 03)'));
						default:
							return reject(new Error('Ett fel har uppstått, var god försök igen lite senare. (Felkod: 04)'));
					}
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);

					return reject(new Error('Ett fel har uppstått, var god kontrollera din uppkoppling och försök igen lite senare. (Felkod: 05)'));
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);

					return reject(new Error('Ett fel har uppstått, var god kontrollera din uppkoppling och försök igen lite senare. (Felkod: 05)'));
				}
			});
		});
	}

	drawImages(nobgImage, overlayImage) {
		const canvas = window.document.createElement('canvas');
		const ctx = canvas.getContext('2d');

		// A3 dimensions, divided by 1.1 to get below the iOS canvas max size (16777216)
		canvas.width = 3508 / 1.1;
		canvas.height = 4961 / 1.1;

		const images = [nobgImage, overlayImage].map(source => {
			return new Promise((resolve, reject) => {
				const img = new Image();
				img.onerror = () => reject(new Error('Ett fel har uppstått, var god testa med en annan bild eller enhet. (Felkod: 06)'));
				img.onload = () => resolve(img);
				img.src = source;
			});
		});

		return Promise.all(images)
			.then(images => {
				images.forEach(image => {
					const imageCoverSize = getCoverSize(
						image.width,
						image.height,
						canvas.width,
						canvas.height
					);
					ctx.drawImage(
						image,
						imageCoverSize.offsetLeft,
						imageCoverSize.offsetTop,
						imageCoverSize.width,
						imageCoverSize.height
					);
				});

				return canvas;
			});
	}

	drawText(canvas, copy, name) {
		const ctx = canvas.getContext('2d');
		ctx.font = "110px Helvetica";
		ctx.fillStyle = "white";

		const words = copy.split(' ');

		let line = '';
		let x = 315;
		let y = 3725;

		words.map( word => {
			const sample = line + word + ' ';
			const metrics = ctx.measureText(sample);

			if (metrics.width > 2560) {
				ctx.fillText(line, x, y);
				line = word + ' ';
				y += 130;
			} else {
				line = sample;
			}

			return null;
		});

		ctx.fillText(line, x, y);

		if( name !== '') {
			ctx.textAlign = "left";
			ctx.font = "900 130px Helvetica";
			const dimensions = ctx.measureText(name);
			ctx.fillText(name, canvas.width - 315 - dimensions.width, y + 240);
		}

		return canvas.toDataURL('image/jpeg', 1);
	}

	restart() {
		this.setState({currentStep: 0});
	}

	render() {
		const {innerHeight, didFail, isLoading, currentStep, mergedImage, errorMessage} = this.state;

		let CurrentStep;
		switch (currentStep) {
			case 0:
				CurrentStep = <Step1 onDone={(file) => this.onStep1Done(file)} />;
				break;
			case 1:
				CurrentStep = <Step2 posterCopy={posterCopy} onAbort={() => this.restart()} onDone={(copy, name) => this.onStep2Done(copy, name)} />;
				break;
			case 2:
				CurrentStep = <Step3 mergedImage={mergedImage} onRestart={() => this.restart()} />;
				break;
			default:

		}

		return (
			<div className="App" style={{minHeight: innerHeight}}>
				{didFail ?
					(<Fail errorMessage={errorMessage} onProceed={() => this.setState({didFail: false})} />) :
					isLoading ?
						(<Loading />) :
						CurrentStep
					}
			</div>
		);
	}
}

export default App;
