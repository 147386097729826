import React from 'react';
import './index.css';

function Fail(props) {
	return (
		<div className="Fail">
			<div>
				<h1 className="Fail__title">Oj!</h1>
				<p className="Fail__body">{props.errorMessage}</p>
			</div>
			<button className="button button--primary" onClick={() => props.onProceed()}>Tillbaka</button>
		</div>
	);
}

export default Fail;
