import React, { useState } from 'react';
import classNames from 'classnames';
import logo from '../assets/images/vision_logotyp_tagline_vit.png';
import './index.css';

function Step1(props) {
	const [confirm, setConfirm] = useState(false);
	return (
		<div className={classNames('Step1', !confirm || 'Step1--confirm')}>
			<img src={logo} className="Step1__logo" alt="logo" />
			{!confirm ?
				(
					<>
						<div className="Step1__content">
							<h1 className="Step1__title">Affisch-generatorn</h1>
							<p className="Step1__body">Välkommen till Visions affisch-generator. Här kan du göra egna kampanjaffischer för att sätta upp på din arbetsplats eller dela i sociala medier.</p>
							<p className="Step1__disclaimer">När du laddar upp din bild i affischgeneratorn så behandlar bolaget remove.bg din bild för att kunna generera bilden och budskapet i rätt format. Genom att fortsätta godkänner du <a href="https://www.remove.bg/privacy" target="_blank" rel="noopener noreferrer">remove.bg personuppgiftspolicy</a>.</p>
						</div>
						<button className="Step1__button button button--primary" onClick={() => setConfirm(true)}>Skapa din affisch</button>
					</>
				) : (
					<>
						<div className="Step1__content">
							<h1 className="Step1__title">Att tänka på för bästa resultat</h1>
							<p className="Step1__body">
								1. Säkerställ att du har öppnat vår affischgenerator via Edge, Safari, Chrome eller Firefox (inte Internet Explorer).<br /><br />
								2. Välj gärna en befintlig eller att ta en ny bild där du har en tröja med mörk eller klar färg.<br /><br />
								3. Ta eller välj en bild i stående porträttformat. Hela ansiktet och en bit av överkroppen ska vara med.<br /><br />
								4. Be någon annan ta bilden om du ska ta en ny. Undvik att använda ”selfie-funktionen”. Välj en plats med bra ljus.<br /><br />
								5. Om du använder en smartphone så använd en så ny modell som möjligt.<br /><br />
								6. Dela affischbilden i sociala medier, via mejl och skriv ut som A3-affisch, så alla medlemmar på din arbetsplats vet vem du är och vem de kan vända sig till för råd och stöd.
							</p>
						</div>
						<div className="Step1__button button button--primary">
							<input className="Step1__input" type="file" accept="image/*" onChange={(e) => props.onDone(e.target.files.item(0))} />
							Ladda upp bild
						</div>
					</>
				)
			}
		</div>
	);
}

export default Step1;
