import React from 'react';
import downloadIcon from '../assets/images/download_icon.png';
import './index.css';

function Step3(props) {
	return (
		<div className="Step3">
			<div className="Step3__border">
				<button className="button button--secondary" onClick={() => props.onRestart()}>Gör en till</button>
				<a className="Step3__download-button button button--secondary" href={props.mergedImage.replace("image/jpeg", "image/octet-stream")} download="vision-affisch.jpg">Ladda ner<img className="Step3__download-icon" src={downloadIcon} alt="download icon" /></a>
			</div>
			<img className="Step3__image" src={props.mergedImage} alt="vision-affisch" />
		</div>
	);
}

export default Step3;
