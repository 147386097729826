import React from 'react';
import './index.css';

function Loading(props) {
	return (
		<div className="Loading">
			<p>Laddar...</p>
		</div>
	);
}

export default Loading;
