import React, { useState } from 'react';
import classNames from 'classnames';
import './index.css';

function Step2(props) {
	const [currentCopy, setCurrentCopy] = useState();
	const [currentName, setCurrentName] = useState();
	return (
		<div className="Step2">
			<div className="Step2__border">
				<button className="button button--secondary" onClick={() => props.onAbort()}>Börja om</button>
				<button className={classNames('button', 'button--secondary', currentCopy || 'button--secondary--disabled')} onClick={() => props.onDone(currentCopy, currentName)}>Skapa affisch</button>
			</div>
			<div className="Step2__content">
				<h1 className="Step2__title">Skriv en text till rubriken <span className="Step2__quote">”Att göra skillnad är mitt uppdrag.”</span></h1>
				<label className="Step2__label" htmlFor="Step2__body">Vad är det viktigaste som du har gjort för Visions medlemmar?</label>
				<textarea className="Step2__body" id="Step2__body" rows="7" maxLength="140" onChange={e => setCurrentCopy(e.target.value)} placeholder="Beskriv din insats (t ex ”Vi förhandlade till oss att anställda i Göteborgs stad får upp till 6 dagars extra semester per år” eller ”I Uppsala har vi drivit igenom nya lönekriterier för chefer som har bidragit till bättre arbetsmiljö för deras anställda.”)"></textarea>
				<p className="Step2__counter">{(currentCopy ? currentCopy.length : 0)} / 140</p>
				<label className="Step2__label--name" htmlFor="Step2__name">Namn
					<input type="text" className="Step2__name" id="Step2__name" onChange={e => setCurrentName(e.target.value)} placeholder="Valfritt" />
				</label>
			</div>
		</div>
	);
}

export default Step2;
